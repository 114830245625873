import "./bootstrap";
import "../css/app.css";

import { createApp, h, DefineComponent } from "vue";
import { createInertiaApp } from "@inertiajs/vue3";
import { resolvePageComponent } from "laravel-vite-plugin/inertia-helpers";
// import * as Sentry from "@sentry/vue";

const appName = import.meta.env.VITE_APP_NAME || "TCGers.nl";

createInertiaApp({
    title: title => `${title} - ${appName}`,
    resolve: name =>
        resolvePageComponent(
            `./Pages/${name}.vue`,
            import.meta.glob<DefineComponent>("./Pages/**/*.vue"),
        ),
    setup({ el, App, props, plugin }) {
        const vm = createApp({ render: () => h(App, props) }).use(plugin);

        // Sentry.init({
        //     dsn: import.meta.env.VITE_SENTRY_DSN_PUBLIC,
        // });

        vm.mount(el);
        return vm;
    },
    progress: {
        color: "#4B5563",
    },
});
